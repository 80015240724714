import { useOutletContext } from "react-router-dom";
import { Button, Container, Form, InputGroup} from "react-bootstrap";
import FormRodape from "../../Components/formularios/FormRodape";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import BadgeValidacao from "../../Components/BadgeValidacao";
import { every } from "lodash";
import { IuseUsuario } from "./Hook/useUsuario";
import { IPessoa } from "../../Interfaces/Pessoa";
import useCidadeEstado from "../../Components/useEstadoCidade";

const UsuarioGerenciamento = () => {
    const Usuario = useOutletContext<IuseUsuario>();
    const [confirmado, setConfirmado] = useState<boolean>(false);
    const [uf, setUF] = useState<string>("");
    const [cidade, setCidade] = useState<string>("");
    const CidadeEstado = useCidadeEstado(uf);

    useEffect(() => {
        if (Usuario.Datasource.Item.UF && uf !== Usuario.Datasource.Item.UF) {
            setUF(Usuario.Datasource.Item.UF);
            setCidade(Usuario.Datasource.Item.CIDADE ?? "");
        }
    }, [Usuario.Datasource.Item.UF, uf, Usuario.Datasource.Item.CIDADE]);

    return (
        <Container className="verticalCenter">
            <Formik
                enableReinitialize
                // validationSchema={}
                onSubmit={(dados : IPessoa) => {
                    const {SENHA, ...data} = dados;
                    Usuario.Controller.Alterar.mutateAsync(data, {
                        onSuccess: () => {
                            Usuario.Navegar('0');
                        }
                    });
                }}
                initialValues={{
                    CD_PESSOA       : Usuario.Datasource.Item.CD_PESSOA    ?? undefined,
                    NOME            : Usuario.Datasource.Item.NOME         ?? "",
                    SENHA           : "",
                    CIDADE          : cidade,
                    CELULAR         : Usuario.Datasource.Item.CELULAR      ?? "",
                    UF              : Usuario.Datasource.Item.UF           ?? "",
                }}
                >
                {({
                    handleSubmit,
                    handleChange,
                    setFieldValue,
                    values,
                    errors,
                }) => {                 
                    return (
                        <Form onSubmit={handleSubmit} onReset={() => Usuario.Navegar('0')}>       
                            <Form.Group className="mt-3">
                                <Form.Label>Nome do Usuário:</Form.Label>
                                <BadgeValidacao validacao={errors.NOME} confirmado={confirmado} />
                                <Form.Control 
                                    name={'NOME'}
                                    type={'text'}
                                    value={values.NOME ?? ""}
                                    onChange={handleChange}
                                    isInvalid={every([errors.NOME, confirmado])}
                                    placeholder="Nome da pessoa..." 
                                    size="lg"
                                />
                            </Form.Group> 

                            <Form.Group className="mt-3">
                                <Form.Label>Celular:</Form.Label>
                                {/* <BadgeValidacao validacao={errors.CELULAR} confirmado={confirmado} /> */}
                                <InputGroup>
                                    <InputGroup.Text id="cdPais">+55</InputGroup.Text>
                                    <Form.Control 
                                        name={'CELULAR'}
                                        type={'text'}
                                        value={values.CELULAR}
                                        // onChange={ handleChange }
                                        onChange={ e => setFieldValue("CELULAR", Usuario.Formatter.setMaskPhone(e.target.value)) }
                                        isInvalid={every([errors.CELULAR, confirmado])}
                                        size="lg"
                                        placeholder="Celular..." 
                                    />
                                </InputGroup>
                            </Form.Group> 

                            <Form.Group className="mt-3">
                                <Form.Label>Estado (UF):</Form.Label>
                                {/* <BadgeValidacao validacao={errors.CD_CIDADE} confirmado={confirmado} /> */}
                                    <Form.Select 
                                        name={'UF'}
                                        value={values.UF}
                                        onChange={ e => {
                                            setFieldValue("UF", e.target.value);
                                            setUF(e.target.value);
                                        }}
                                        // isInvalid={every([errors.CD_CIDADE, confirmado])} 
                                        size="lg"
                                    >
                                    <option value={undefined}>Selecione uma UF</option>
                                    {CidadeEstado.Estados.map((index: string) => (
                                        <option key={index} value={index}>
                                            {index}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>   

                            <Form.Group className="mt-3">
                                <Form.Label>Cidade:</Form.Label>
                                    <Form.Select
                                        name={'CIDADE'}
                                        value={values.CIDADE}
                                        onChange={ e => setFieldValue("CIDADE", e.target.value) }
                                        size="lg"
                                        disabled={uf === ''}
                                    >
                                    <option value={cidade}>{cidade}</option>
                                    {CidadeEstado.Cidades.map((index: string) => (
                                        <option key={index} value={index}>
                                            {index}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>     

                            <FormRodape>
                                <Button variant="outline-danger" size="lg" onClick={() => {}} type="reset">Cancelar</Button>
                                <Button variant="success" size="lg" onClick={() => {setConfirmado(true)}} type="submit">Confirmar</Button>
                            </FormRodape>
                        </Form>
                    )
                }}
            </Formik>            
        </Container>
    )
}

export default UsuarioGerenciamento;