import { useMemo } from "react";
import { IUsePessoaController } from "../../../Controllers/Pessoa";
import { IPessoa } from "../../../Interfaces/Pessoa";


interface Props {
    Controller: IUsePessoaController;
}

const useUsuarioDatasource = ({Controller} : Props) : IUseUsuarioDatasource => {
    // const Lista = useMemo<IJogo[]>(() => {
    //     if (Controller.Listar.isFetched && Controller.Listar.isSuccess && Controller.Listar.data) {
    //         return Controller.Listar.data.sort((a, b) => {
    //             return (b.CD_JOGO ?? 0) - (a.CD_JOGO ?? 0);
    //         });
    //     } else {
    //         return [{} as IJogo];
    //     };
    // }, [Controller.Listar, Controller.ListarJogaveis, AlteraJogo]);

    // const Item = useMemo<IJogo>(() => {
    //     if (Controller.Buscar.isSuccess && Controller.Buscar.data) {
    //         const sortedData = { ...Controller.Buscar.data };
    //         if (sortedData.COMISSAO) {
    //             sortedData.COMISSAO = [...sortedData.COMISSAO].sort((a, b) => {
    //                 return b.PCE_PERCENTUAL - a.PCE_PERCENTUAL;
    //             });
    //         };

    //         return sortedData;
    //     } else {
    //         return {} as IJogo;
    //     };
    // }, [Controller.Buscar.isSuccess, Controller.Buscar.data]);

    const Item = useMemo<IPessoa>(() => {
        if (Controller.Buscar.isSuccess && Controller.Buscar.data) {
            return Controller.Buscar.data;
        } else {
            return {} as IPessoa;
        };
    }, [Controller.Buscar]);

    const BuscaNome = useMemo<IPessoa[]>(() => {
        if (Controller.BuscarNome.isSuccess && Controller.BuscarNome.data) {
            return Controller.BuscarNome.data;
        } else {
            return [{} as IPessoa];
        };
    }, [Controller.BuscarNome]);

    return {
        BuscaNome,
        Item
    }
}

export interface IUseUsuarioDatasource {
    BuscaNome   : IPessoa[];
    Item        : IPessoa;
}

export default useUsuarioDatasource;