import { useContext } from "react";
import { CtxApostaCompra } from "../../../../Contexts/ctxApostaCompra";
import { IPessoa } from "../../../../Interfaces/Pessoa";

interface Props {
    apostador: IPessoa;
}

const ConfirmacaoE1 = ({apostador} : Props) => {
    const {Jogo, formatter, Carrinho} = useContext(CtxApostaCompra);
    const cambista = apostador && apostador.NOME && apostador.NOME?.length > 0;

    return (
        <>
            <div>
                {
                    `Deseja confirmar a ${cambista ? 'venda' : 'compra'} de ${Carrinho.Read && Carrinho.Read.length ? Carrinho.Read.length : 0} Aposta(s) no valor total de ${
                        formatter.setMaskMoney(Carrinho.Read && Carrinho.Read.length ? Carrinho.Read.length * Jogo.Datasource.Item.VLR_APOSTA : 0 , "R$")
                    }?
                    Até a confirmação do pagamento${cambista ? ' na aba "Confirmação de apostas"' : ''}, as apostas ficarão pendentes. Após a confirmação, será habilitado a visualização do comprovante da aposta.
                    `
                }
                {
                    cambista && <div className="mt-3">{`Apostador: ${apostador.NOME}`}</div>
                }
                {
                    cambista && <div>{`Celular: ${formatter.setMaskPhone(apostador.CELULAR)}`}</div>
                }
                {
                    cambista && apostador.CIDADE && <div>{`Cidade: ${apostador.CIDADE} - ${apostador.UF}`}</div>
                }
            </div>
        </>
    )
}

export default ConfirmacaoE1;