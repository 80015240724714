import { Outlet, useParams } from "react-router-dom";
import useUsuario from "./Hook/useUsuario";

const Usuario = () => {
    const { cdUsuario } = useParams();
    const Usuario = useUsuario(cdUsuario);

    return (
        <Outlet context={Usuario} />
    );
}

export default Usuario;