import { useContext, useEffect, useState } from "react";
import useApostaController, { IUseApostaController } from "../../../../Controllers/Aposta"
import useApostaDatasource, { IUseApostaDatasource } from "./datasource";
import { CtxAppParams } from "../../../../Contexts/ctxAppParams";
import { EPermissao } from "../../../../Constants/permissao";

const useAposta = (cdJogo?: string) : IUseAposta => {
    const AppParams = useContext(CtxAppParams);
    const AlteraAposta = !!AppParams.Permissoes?.find(permissao => permissao.REGRA === String(EPermissao.ALTERA_PULE));
    const Gerencial = !!AppParams.Permissoes?.find(permissao => permissao.REGRA === String(EPermissao.GERENCIA_PULE));

    const [resultadosOrdenacao, setResultadosOrdenacao] = useState<string>('Maior Pontuação');
    const [resultadosPesquisa, setResultadosPesquisa] = useState<string>('');

    const Controller = useApostaController({
        updateQueryKey: 'Jogo',
        enableQuery: cdJogo === undefined,
        queryValue: (cdJogo) && (Number(cdJogo) > 0) ? cdJogo : undefined,
        enableCambista: AlteraAposta,
        enableGerencial: Gerencial
    });
    const Datasource = useApostaDatasource({
        Controller: Controller,
        ResultadosFiltro: {
            Ordenacao   : {
                Write: setResultadosOrdenacao,
                Read : resultadosOrdenacao
            },
            Pesquisa    : {
                Write: setResultadosPesquisa,
                Read : resultadosPesquisa
            }
        }
    });

    useEffect(() => {
        if (cdJogo && Number(cdJogo) > 0 && Gerencial) {
            Controller.ListarTodosJogo.refetch();
        }
    }, [cdJogo]); //eslint-disable-line

    return {
        Controller: Controller,
        Datasource: Datasource,
        Resultados: {
            Ordenacao   : {
                Write: setResultadosOrdenacao,
                Read : resultadosOrdenacao
            },
            Pesquisa    : {
                Write: setResultadosPesquisa,
                Read : resultadosPesquisa
            }
        }
    }
}

export interface IUseAposta {
    Controller: IUseApostaController;
    Datasource: IUseApostaDatasource;
    Resultados: {
        Ordenacao   : {
            Write: React.Dispatch<React.SetStateAction<string>>;
            Read : string;
        },
        Pesquisa    : {
            Write: React.Dispatch<React.SetStateAction<string>>;
            Read : string;
        }
    }
}

export default useAposta;