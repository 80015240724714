import { useState } from "react";
import { IUsePessoaController } from "../../../Controllers/Pessoa";
import { IGenericInformation } from "../../../Interfaces";
import { IPessoa } from "../../../Interfaces/Pessoa";

interface Props {
    formatter: any;
    Controller: IUsePessoaController;
}

const useUsuarioService = ({formatter, Controller} : Props) => {
    const [pesquisaTipo, setPesquisaTipo] = useState<'CELULAR' | 'NOME'>();
    const pesquisaDefault = [
            {
                CHAVE: 'CELULAR',
                VALOR: ''
            },
            {
                CHAVE: 'NOME',
                VALOR: ''
            }
        ];
    const [pesquisaGenerico, setPesquisaGenerico] = useState<IGenericInformation[]>(pesquisaDefault);

    const pesquisaBusca = ({
        encontrado,
        naoEncontrado
    }: {
        encontrado?: (ret: IPessoa | IPessoa[]) => void;
        naoEncontrado?: () => void;
    }) => {
        const tipo = pesquisaGenerico.find(x => String(x.VALOR ?? "") !== '')?.CHAVE as 'CELULAR' | 'NOME';
        setPesquisaTipo(tipo);
        const valorBusca = tipo === 'CELULAR' 
            ? formatter.getPhone(pesquisaGenerico.find(x => x.CHAVE === tipo)?.VALOR ?? "") 
            : String((pesquisaGenerico.find(x => x.CHAVE === tipo)?.VALOR ?? "")).trim();

        if (tipo === 'CELULAR') {
            if (valorBusca?.length === 11) {
                Controller.VerificarCadastro.mutateAsync({CELULAR: valorBusca}, {
                    onSuccess: (ret: any) => {
                        if (ret["EXISTE"]) {
                            Controller.Buscar.mutateAsync({CELULAR: valorBusca}, {
                                onSuccess: (ret: IPessoa) => {
                                    if (encontrado) {
                                        encontrado(ret);
                                    }
                                }
                            })
                        } else {
                            if (naoEncontrado) {
                                naoEncontrado();
                            }
                        }
                    }
                })                            
            } else {
                alert('Celular informado inválido.');
            }
        } else if (tipo === 'NOME') {
            if (valorBusca?.length >= 3) {
                Controller.BuscarNome.mutateAsync({NOME: valorBusca}, {
                    onSuccess: (ret: IPessoa[]) => {
                        if (ret.length > 0) {
                            if (encontrado) {
                                encontrado(ret);
                            }
                        } else {
                            if (naoEncontrado) {
                                naoEncontrado();
                            }
                        }                                    
                    }
                })
            } else {
                alert('Digite um nome válido');
            }
        } else {
            alert('Insira o celular ou o nome do apostador.');
        }
    }

    return {
        Pesquisa: {
            buscar: pesquisaBusca,
            Tipo: {
                read: pesquisaTipo,
                write: setPesquisaTipo
            },
            Generico: {
                read: pesquisaGenerico,
                write: setPesquisaGenerico
            }
        }
    }
}

export interface IUseUsuarioService {
    Pesquisa: {
        buscar: ({ encontrado, naoEncontrado }: {
            encontrado?: (ret: IPessoa | IPessoa[]) => void;
            naoEncontrado?: () => void;
        }) => void;
        Tipo: {
            read: "CELULAR" | "NOME" | undefined;
            write: React.Dispatch<React.SetStateAction<"CELULAR" | "NOME" | undefined>>;
        };
        Generico: {
            read: IGenericInformation[]
            write: React.Dispatch<React.SetStateAction<IGenericInformation[]>>
        };
    }
}

export default useUsuarioService;