import { useOutletContext } from "react-router-dom";
import { IUseJogo } from "../Hook/useJogo";
import { Badge, Button, Col, Container, Form, InputGroup } from "react-bootstrap";
import { IAposta, ILancamento } from "../../../Interfaces/Aposta";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { EStatus, ETipo } from "../../../Interfaces/Jogo";
import { CtxAppParams } from "../../../Contexts/ctxAppParams";
import { EPermissao } from "../../../Constants/permissao";
import ModalConfirmacao from "../../../Components/ModalConfirmacao";
import useColorScheme from "../../../Components/aposta/colorScheme";
import CartaoPuleAposta from "../../../Components/pule/cartaoPuleAposta";
import usePremioController from "../../../Controllers/Premio";
import PDFJogoResultado from "../../../Reports/jogoResultado";
import useMask from "../../../Components/useMask";
import { FaReceipt, FaSearch, FaStar, FaTimes } from "react-icons/fa";
import TabelaMais from "../../../Components/tabela/tabelaMais";
import { MdFilterList } from "react-icons/md";

const JogoResultado = () => {
    const Jogo = useOutletContext<IUseJogo>();
    const AppParams = useContext(CtxAppParams);
    const Gerencial = !!AppParams.Permissoes?.find(permissao => permissao.REGRA === String(EPermissao.GERENCIA_PULE));
    const [showModal, setShowModal] = useState<boolean>(false);
    const [selecionado, setSelecionado] = useState<IAposta>({} as IAposta);
    const [premio, setPremio] = useState<number>();
    const Colors = useColorScheme();
    const Premio = usePremioController({updateQueryKey: 'Premio'});
    const formatter = useMask();
    const fontSizeDefault = '0.9rem';

    const tituloConcursoRef = useRef<any>(null);
    const [tituloConcursoSticky, setTituloConcursoSticky] = useState(false);
    const tituloApostasRef = useRef<any>(null);
    const [tituloApostasSticky, setTituloApostasSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (tituloConcursoRef.current) {
                const rect = tituloConcursoRef.current.getBoundingClientRect();
                setTituloConcursoSticky(rect.top <= 0);
            }

            if (tituloApostasRef.current) {
                const rect = tituloApostasRef.current.getBoundingClientRect();
                setTituloApostasSticky(rect.top <= 0);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const maxDigits = useMemo(() => {
        return Jogo.TipoJogo?.VLR_MAX.toString().length;
    }, [Jogo.TipoJogo?.VLR_MAX]);

    const colunasAposta = useMemo(() => [
        {
            Header: 'Apostador',
            id: "nome",
            Cell: (row: any) : any => <div style={{fontSize: fontSizeDefault}}>{row.row.original['NOME']}</div>
        },
        {
            Header: 'Lançamentos',
            id: "aposta",
            Cell: (row: any) : any => {
                const Lancamentos : ILancamento[] = row.row.original['LANCAMENTO'];
                
                return <div className="d-flex flex-wrap">{
                        Lancamentos && Lancamentos.map(lancamento => {
                        return (
                            <Badge 
                                pill 
                                style={{fontSize: fontSizeDefault, margin: '1px', width: '1.7rem'}}
                                className="d-flex flex-nowrap justify-content-center"
                                bg={lancamento.ACERTO ? 'primary' : 'light'}
                                text={lancamento.ACERTO ? 'light' : 'dark'}
                            >
                                {lancamento.NUMERO.toString().padStart(maxDigits, '0')}
                            </Badge>
                        )
                    })
                }</div>
            }
        },
        {
            Header: <div className="w-100 d-flex justify-content-center"><FaStar /></div>,
            id: "pontuacao",
            Cell: (row: any) : any => {
                return (
                    <div className="d-flex w-100 justify-content-center">
                        <Badge 
                            style={{fontSize: fontSizeDefault}}
                            bg={row.row.original['PONTO'] === Jogo.TipoJogo?.QTD_MAX ? 'success' : 'dark'}
                            text={row.row.original['PONTO'] === Jogo.TipoJogo?.QTD_MAX ? 'light' : 'light'}
                        >
                            {row.row.original['PONTO']}
                        </Badge>
                    </div>
                )
            }
        },
    ], [maxDigits, Jogo.TipoJogo?.QTD_MAX]);

    const colunasConcurso = useMemo(() => [
        {
            Header: 'Concurso',
            id: "concurso",
            Cell: (row: any) : any => <div style={{fontSize: fontSizeDefault}}>{row.row.original['NM_CONCURSO']}</div>
        },
        {
            Header: 'Lançamentos',
            id: "lancamentos",
            Cell: (row: any) : any => {
                const Lancamentos : number[] = row.row.original['LANCAMENTO'];
                
                return <div className="d-flex flex-wrap">{
                    Lancamentos && Lancamentos.map(lancamento => {
                        return (
                            <Badge 
                                pill 
                                style={{fontSize: fontSizeDefault, margin: '1px', width: '1.7rem'}}
                                className="d-flex flex-nowrap justify-content-center"
                                bg={'warning'}
                                text="dark"
                            >
                                {lancamento.toString().padStart(maxDigits, '0')}
                            </Badge>
                        )
                    })
                }</div>
            }
        },
        {
            Header: 'Parciais',
            id: 'parciais',
            Cell: (row: any) : any => <div style={{fontSize: fontSizeDefault}}>{row.row.original['PARCIAL']}</div>
        },
        {
            Header: 'Hora',
            id: "data",
            Cell: (row: any) : any => <div style={{whiteSpace: "nowrap", fontSize: fontSizeDefault}}>{formatter.setDate(new Date(row.row.original['DT_CONCURSO']), { hour: "2-digit", minute: "2-digit" }, false)}</div>
        }
    ], [maxDigits, formatter]);    

    return (
        <Container className="verticalCenter">
            <ModalConfirmacao
                titulo={'Atribuir prêmio'}
                show={showModal} 
                onHide={setShowModal}
                disabledConfirm={Premio.Cadastrar.isLoading || !premio}
                onSubmitHandler={() => {Premio.Cadastrar.mutateAsync(
                    {CD_JOGO_COMISSAO: premio, CD_JOGO_PULE_APOSTA: selecionado.CD_JOGO_PULE_APOSTA}, {
                        onSuccess: () => {
                            setShowModal(false);
                            setPremio(undefined);
                            Jogo.Controller.Buscar.mutate({CD_JOGO: Jogo.Datasource.Item.CD_JOGO});
                        }
                    }
                )}}
            >
                <Container className="verticalCenter">
                {
                    selecionado && ((selecionado.CD_JOGO_PULE_APOSTA ?? 0) > 0) ?
                        <>
                            <CartaoPuleAposta
                                Aposta={selecionado}
                                Colors={Colors}
                                MaxDigits={maxDigits}
                                Detalhado={true}
                                Gerencial={Gerencial}
                            />

                            <Col>
                                <Form.Group className="mt-3">
                                    <Form.Label>Prêmio:</Form.Label>
                                    <Form.Select 
                                        name={'CD_JOGO_COMISSAO'}
                                        value={premio ?? ""}
                                        onChange={ e => {
                                            setPremio(Number(e.target.value));
                                        }}
                                        size="lg"
                                    >
                                        <option key={''} value={''} disabled={true}>Selecione</option>
                                        {Jogo.Controller.Buscar.isSuccess && Jogo.Controller.Buscar.data && Jogo.Datasource.Item && 
                                        Jogo.Datasource.Item.COMISSAO && Jogo.Datasource.Item.COMISSAO.length > 0 && 
                                        Jogo.Datasource.Item.COMISSAO.filter(premio => premio.TIPO === ETipo.Outros).map((premio) => (
                                            <option key={premio.CD_JOGO_COMISSAO} value={premio.CD_JOGO_COMISSAO}>
                                                {premio.NOME}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </>
                    :
                        <>Aposta não encontrada</>
                }
                </Container>
            </ModalConfirmacao>

            {
                [EStatus.Aberto, EStatus.Rascunho].includes(Jogo.Datasource.Item.STATUS) &&
                    <div className="mt-3">
                        O Jogo ainda não começou. Os Resultados serão exibidos assim que for iniciado.
                    </div>                
            }
            {
                Jogo.Concurso.Datasource.ListaJogo && Jogo.Concurso.Datasource.ListaJogo.length > 0 &&
                <div className="mt-3 w-100">
                    <h3 ref={tituloConcursoRef} style={{position: "sticky", top: 0, zIndex: 99, paddingTop: tituloConcursoSticky ? '4rem' : '0rem', backgroundColor: 'white'}}>Concursos</h3>
                    <TabelaMais
                        dados={Jogo.Concurso.Datasource.ListaJogo} 
                        colunas={colunasConcurso}
                    />
                </div>
            }           
            {
                Jogo.Aposta.Controller.ListarJogo.data && Jogo.Aposta.Controller.ListarJogo.data.length > 0 &&
                <div className="mt-3 w-100">
                    <h3 ref={tituloApostasRef} className="mt-3" style={{position: "sticky", top: 0, zIndex: 99, paddingTop: tituloApostasSticky ? '4rem' : '0rem', backgroundColor: 'white'}}>Apostas</h3>
                    <div className="d-flex w-100 align-items-center justify-content-end">
                        <Button 
                            className="me-3"
                            variant="warning"
                            style={{fontSize: fontSizeDefault}}
                            onClick={() => PDFJogoResultado(
                                {
                                    Jogo: Jogo.Datasource.Item,
                                    Apostas: Jogo.Aposta.Controller.ListarJogo.data ?? [{} as IAposta],
                                    Concursos: Jogo.Concurso.Datasource.ListaJogo,
                                    MaxDigits: maxDigits,
                                    formatter: formatter,
                                    url: window.location.origin
                                }
                            )}
                        >
                            <FaReceipt /> Imprimir Resultados
                        </Button>

                        <Button 
                            variant="primary"
                            style={{fontSize: fontSizeDefault}}
                            onClick={() => {
                                switch(Jogo.Aposta.Resultados.Ordenacao.Read) {
                                    case 'Apostador A-Z':
                                        Jogo.Aposta.Resultados.Ordenacao.Write('Apostador Z-A')
                                        break;
                                    case 'Apostador Z-A':
                                        Jogo.Aposta.Resultados.Ordenacao.Write('Maior Pontuação')
                                        break;
                                    case 'Maior Pontuação':
                                        Jogo.Aposta.Resultados.Ordenacao.Write('Menor Pontuação')
                                        break;
                                    case 'Menor Pontuação':
                                        Jogo.Aposta.Resultados.Ordenacao.Write('Apostador A-Z')
                                        break;
                                }
                            }}
                        >
                            <MdFilterList style={{zoom: '1.2'}} /> Ordenar por: {Jogo.Aposta.Resultados.Ordenacao.Read}
                        </Button>
                    </div>

                    <div className="d-flex w-100 align-content-center">
                        <Form.Group className="my-3 w-100">
                            <InputGroup>
                                <InputGroup.Text id="iconePesquisa"><FaSearch /></InputGroup.Text>
                                <Form.Control
                                    name={'pesquisa'}
                                    autoComplete="false"
                                    type={'text'}
                                    value={Jogo.Aposta.Resultados.Pesquisa.Read}
                                    onChange={(e) => Jogo.Aposta.Resultados.Pesquisa.Write(String(e.target.value))}
                                    placeholder="Pesquise pelo nome do apostador..." 
                                    size="lg"
                                />
                                {
                                    Jogo.Aposta.Resultados.Pesquisa.Read.length > 0 &&
                                    <Button 
                                        onClick={() => Jogo.Aposta.Resultados.Pesquisa.Write('')}
                                        variant="dark"
                                    >
                                        <FaTimes />
                                    </Button>
                                }
                            </InputGroup>                    
                        </Form.Group>
                    </div>
                    {
                        Jogo.Aposta.Datasource.ListaJogo && Jogo.Aposta.Datasource.ListaJogo.length > 0 ?
                            <TabelaMais
                                pagination
                                dados={Jogo.Aposta.Datasource.ListaJogo} 
                                colunas={colunasAposta}
                                handleClick={(e: IAposta) => {
                                    if (Gerencial) {
                                        setSelecionado(e);
                                        setShowModal(true);
                                    }
                                }}                      
                            />
                        :
                            <div className="w-100 d-flex justify-content-center mt-3">Nenhuma aposta encontrada</div>
                    }                    
                </div>
            }    
        </Container>
    )
}

export default JogoResultado;