import { useLayoutEffect, useMemo } from "react";
import { EMode } from "../../../Interfaces";
import usePagina from "../../../Components/usePagina";
import usePessoaController, { IUsePessoaController } from "../../../Controllers/Pessoa";
import useMask from "../../../Components/useMask";
import useUsuarioService, { IUseUsuarioService } from "./useUsuarioService";
import useUsuarioDatasource, { IUseUsuarioDatasource } from "./datasource";

const useUsuario = (cdUsuario?: string) : IuseUsuario => {
    const Codigo = cdUsuario;
    const formatter = useMask();
    const {NavegaPagina} = usePagina();
    const Controller = usePessoaController('Usuario', false, false);
    const Service = useUsuarioService({formatter, Controller});
    const Datasource = useUsuarioDatasource({Controller: Controller});
    //const Grid = useUsuarioGrid({Controller: Controller});

    useLayoutEffect(() => {
        if (Codigo && Number(Codigo) > 0) {
            Controller.Buscar.mutate({CELULAR: Codigo});
        } else {
            Controller.Buscar.reset();
        }
    }, [Codigo]); //eslint-disable-line

    const Modo : EMode = useMemo(() => {
        switch (Codigo) {
            case '0':
                return EMode.Browse;
            case '-1':
                return EMode.Include;
            default:
                return EMode.Edit;
        }
    }, [Codigo]);

    const Navegar = (Codigo: string) => {
        if (Codigo === '0') {
            NavegaPagina('Usuario', [], []);
        } else {
            NavegaPagina('UsuarioGerenciamento', ['cdUsuario'], [Codigo]);
        }
    }

    return {
        Datasource  : Datasource,
        //Grid        : Grid,
        Modo        : Modo,
        Navegar     : Navegar,
        Controller  : Controller,
        Service     : Service,
        Formatter   : formatter
    }
};

export interface IuseUsuario {
    Datasource : IUseUsuarioDatasource;
    //Grid       : IuseUsuarioGrid;
    Modo       : EMode;
    Navegar    : (CodigoControle: string) => void;
    Controller : IUsePessoaController;
    Service    : IUseUsuarioService;
    Formatter  : any;
}

export default useUsuario;