import { useOutletContext } from "react-router-dom"
import { Button, Container, Form } from "react-bootstrap";
import FormRodape from "../../Components/formularios/FormRodape";
import { IuseUsuario } from "./Hook/useUsuario";
import VinculaApostadorE2 from "../Jogo/Aposta/Compra/vinculaApostadorE2";
import { FaLock, FaSearch } from "react-icons/fa";
import { useContext, useState } from "react";
import Tabela from "../../Components/tabela";
import { IPessoa } from "../../Interfaces/Pessoa";
import { FaPencil } from "react-icons/fa6";
import { CtxAppParams } from "../../Contexts/ctxAppParams";
import { EPermissao } from "../../Constants/permissao";
import ModalConfirmacao from "../../Components/ModalConfirmacao";
import { toast } from "react-toastify";

const UsuarioPesquisa = () => {
    const Usuario = useOutletContext<IuseUsuario>();
    const [exibeResultado, setExibeResultado] = useState<boolean>(false);
    const [exibeTrocaSenha, setExibeTrocaSenha] = useState<boolean>(false);
    const trocaSenhaDefault = {
        SENHA: '',
        SENHA_CONFIRMA: ''
    };
    const [trocaSenha, setTrocaSenha] = useState(trocaSenhaDefault);

    const AppParams = useContext(CtxAppParams);
    const AlteraSenha = !!AppParams.Permissoes?.find(permissao => permissao.REGRA === String(EPermissao.ALTERA_USUARIO_SENHA));

    return (
        <Container className="verticalCenter">
            <ModalConfirmacao
                titulo={'Trocar senha de usuário'}
                show={exibeTrocaSenha}          
                disabledConfirm={Usuario.Controller.AlterarSenha.isLoading}
                onHide={() => {setExibeTrocaSenha(false)}}
                onSubmitHandler={() => {
                    if (trocaSenha.SENHA === trocaSenha.SENHA_CONFIRMA) {
                        Usuario.Controller.AlterarSenha.mutateAsync({CD_PESSOA: Usuario.Datasource.Item.CD_PESSOA, SENHA: trocaSenha.SENHA}, {
                            onSuccess: () => {
                                setTrocaSenha(trocaSenhaDefault);
                                toast('Senha alterada com sucesso!');
                                setExibeTrocaSenha(false);                                
                            }
                        });
                    } else {
                        alert('Senha e confirmação de senha devem coincidir!')
                    }
                }}
            >
                <div>
                    <div>
                        {`Usuário ${Usuario.Datasource.Item.NOME} - Celular: ${Usuario.Formatter.setMaskPhone(Usuario.Datasource.Item.CELULAR)}. - Endereço: ${Usuario.Datasource.Item.CIDADE} - ${Usuario.Datasource.Item.UF}`}
                    </div>
                    <div>
                        {/* TODO: Criar componente para troca de senha */}
                        <Form.Group className="mt-3">
                            <Form.Label>Nova Senha:</Form.Label>
                            <Form.Control
                                name={'SENHA'}
                                type={'password'}
                                value={trocaSenha.SENHA}
                                onChange={(e) => setTrocaSenha((prev) => ({...prev, SENHA: e.target.value}))}
                                size="lg"
                                placeholder="Senha..." 
                            />
                        </Form.Group>

                        <Form.Group className="mt-3">
                            <Form.Label>Confirmar senha:</Form.Label>
                            <Form.Control
                                name={'SENHA_CONFIRMA'}
                                type={'password'}
                                value={trocaSenha.SENHA_CONFIRMA}
                                onChange={(e) => setTrocaSenha((prev) => ({...prev, SENHA_CONFIRMA: e.target.value}))}
                                size="lg"
                                placeholder="Repita a Senha..." 
                            />
                        </Form.Group>
                    </div>
                </div>
            </ModalConfirmacao>

            <div className="w-100 m-3">
                <VinculaApostadorE2 
                    setVinculaApostador={Usuario.Service.Pesquisa.Generico.write} 
                    vinculaApostador={Usuario.Service.Pesquisa.Generico.read} 
                    formatter={Usuario.Formatter} 
                />
            </div>

            {
                exibeResultado && <div className="m-3 w-100">
                    <Tabela
                        dados={
                            String(Usuario.Service.Pesquisa.Tipo.read) === 'CELULAR'
                                ? [Usuario.Datasource.Item]
                                : Usuario.Datasource.BuscaNome
                        } 
                        colunas={[
                            {
                                Header: 'Nome',
                                id: 'nome',
                                accessor: 'NOME'
                            },
                            {
                                Header: 'Celular',
                                id: "celular",
                                Cell: (row: any) : any => Usuario.Formatter.setMaskPhone(row.row.original['CELULAR'])
                            },
                            {
                                Header: '',
                                id: "acoes",
                                Cell: (row: any) : any => {
                                    const Selecionado: IPessoa = row.row.original;
                                    return (
                                        <div className="d-flex w-100 justify-content-evenly">
                                            <Button 
                                                size="sm" 
                                                variant="warning"
                                            >
                                                <FaPencil />
                                            </Button>

                                            {
                                                AlteraSenha && <Button 
                                                    size="sm" 
                                                    variant="success"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();

                                                        Usuario.Controller.Buscar.mutateAsync({CELULAR: Selecionado.CELULAR}, {
                                                            onSuccess: () => {
                                                                setExibeTrocaSenha(true);
                                                            },
                                                            onError: () => {
                                                                alert('Erro ao buscar usuário selecionado, verifique sua conexão.');
                                                            }
                                                        });
                                                    }}
                                                >
                                                    <FaLock />
                                                </Button>
                                            }
                                        </div>
                                    )
                                }
                            }
                        ]} 
                        handleClick={(e: IPessoa) => {
                            Usuario.Navegar(String(e.CELULAR))
                        }}
                    />
                </div>
            }
            

            <FormRodape>
                <Button 
                    size="lg" 
                    variant="success" 
                    disabled={Usuario.Controller.BuscarNome.isLoading || Usuario.Controller.Buscar.isLoading}
                    onClick={() => {
                        Usuario.Service.Pesquisa.buscar({
                            encontrado(ret) {
                                setExibeResultado(true);
                            },
                            naoEncontrado() {
                                setExibeResultado(false);
                                alert('Nenhum usuário encontrado, verifique os dados da sua pesquisa.');
                            },
                        });
                    }}
                >
                    <FaSearch/> Pesquisar
                </Button>
                </FormRodape>
        </Container>         
    )
}

export default UsuarioPesquisa;