import { useMemo } from "react";
import { IUseApostaController } from "../../../../Controllers/Aposta";
import { IAposta } from "../../../../Interfaces/Aposta";
import { EPuleStatus, IPule } from "../../../../Interfaces/Pule";


interface Props {
    Controller: IUseApostaController;
    ResultadosFiltro: {
        Ordenacao   : {
            Write: React.Dispatch<React.SetStateAction<string>>;
            Read : string;
        },
        Pesquisa    : {
            Write: React.Dispatch<React.SetStateAction<string>>;
            Read : string;
        }
    }
}

const useApostaDatasource = ({Controller, ResultadosFiltro} : Props) : IUseApostaDatasource => {

    const ListaJogo = useMemo<IAposta[]>(() => {
            if (Controller.ListarJogo.isFetched && Controller.ListarJogo.isSuccess && Controller.ListarJogo.data) {
                return Controller.ListarJogo.data
                .filter((item) => {
                    const pesquisa = ResultadosFiltro.Pesquisa.Read.toLowerCase();
                    if (pesquisa.length >= 3) {
                        return !pesquisa || (item.NOME ?? "").toLowerCase().includes(pesquisa.trim());
                    } else {
                        return true;
                    }
                })
                .sort((a, b) => {
                    if (ResultadosFiltro.Ordenacao.Read === "Apostador A-Z") {
                        return (a.NOME ?? "").localeCompare(b.NOME ?? "");
                    } else if (ResultadosFiltro.Ordenacao.Read === "Apostador Z-A") {
                        return (b.NOME ?? "").localeCompare(a.NOME ?? "");
                    } else if (ResultadosFiltro.Ordenacao.Read === "Menor Pontuação") {
                        return (a.PONTO ?? 0) - (b.PONTO ?? 0);
                    } else { // Padrão Maior Pontuação
                        return (b.PONTO ?? 0) - (a.PONTO ?? 0);
                    }
                });
            } else {
                return [{} as IAposta];
            };
    }, [Controller.ListarJogo.data, ResultadosFiltro]); //eslint-disable-line

    const ListaUsuario = useMemo<IPule[]>(() => {
        if (Controller.ListarUsuario.isFetched && Controller.ListarUsuario.isSuccess && Controller.ListarUsuario.data) {
            // return Controller.ListarUsuario.data;
            return Controller.ListarUsuario.data.sort((a, b) => (b.CD_JOGO_PULE ?? 0) - (a.CD_JOGO_PULE ?? 0));
        } else {
            return [{} as IPule];
        };
    }, [Controller.ListarUsuario.data]); //eslint-disable-line

    const ListaUsuarioJogo = useMemo<IPule[]>(() => {
        if (Controller.ListarUsuarioJogo.isFetched && Controller.ListarUsuarioJogo.isSuccess && Controller.ListarUsuarioJogo.data) {
            // return Controller.ListarUsuarioJogo.data;
            return Controller.ListarUsuarioJogo.data.sort((a, b) => (b.CD_JOGO_PULE ?? 0) - (a.CD_JOGO_PULE ?? 0));
        } else {
            return [{} as IPule];
        };
    }, [Controller.ListarUsuarioJogo.data]); //eslint-disable-line
    
    const ListaCambistaJogo = useMemo<IPule[]>(() => {
        if (Controller.ListarCambistaJogo.isFetched && Controller.ListarCambistaJogo.isSuccess && Controller.ListarCambistaJogo.data) {
            // return Controller.ListarCambistaJogo.data;
            return Controller.ListarCambistaJogo.data.sort((a, b) => (b.CD_JOGO_PULE ?? 0) - (a.CD_JOGO_PULE ?? 0));
        } else {
            return [{} as IPule];
        };
    }, [Controller.ListarCambistaJogo.data]); //eslint-disable-line

    const ListaTodosJogoPendente = useMemo<IPule[]>(() => {
        if (Controller.ListarTodosJogo.isFetched && Controller.ListarTodosJogo.isSuccess && Controller.ListarTodosJogo.data) {
            return Controller.ListarTodosJogo.data.filter(pule => pule.STATUS === EPuleStatus.Aberto).sort((a, b) => (b.CD_JOGO_PULE ?? 0) - (a.CD_JOGO_PULE ?? 0));
        } else {
            return [{} as IPule];
        };
    }, [Controller.ListarTodosJogo.data]); //eslint-disable-line

    const ListaTodosJogoConfirmado = useMemo<IPule[]>(() => {
        if (Controller.ListarTodosJogo.isFetched && Controller.ListarTodosJogo.isSuccess && Controller.ListarTodosJogo.data) {
            return Controller.ListarTodosJogo.data.filter(pule => pule.STATUS === EPuleStatus.Finalizado).sort((a, b) => (b.CD_JOGO_PULE ?? 0) - (a.CD_JOGO_PULE ?? 0));
        } else {
            return [{} as IPule];
        };
    }, [Controller.ListarTodosJogo.data]); //eslint-disable-line

    const TotaisTodosJogoConfirmado = useMemo(() => {
        const totalPorCambista: { CD_CAMBISTA: number | null, NOME_CAMBISTA: string | null, TOTAL: number, QTD_PULE: number, QTD_APOSTA: number }[] = [];
    
        // Agrupar apostas por CD_CAMBISTA
        ListaTodosJogoConfirmado.forEach(aposta => {
            const valorAposta = parseFloat(String(aposta.VLR_APOSTA ?? "0"));
        
            // Encontrar o cambista atual
            let cambista = totalPorCambista.find(c => c.CD_CAMBISTA === aposta.CD_CAMBISTA);
        
            // Se não encontrar, adicionar um novo cambista
            if (!cambista) {
                totalPorCambista.push({
                    CD_CAMBISTA: aposta.CD_CAMBISTA!,
                    NOME_CAMBISTA: aposta.NOME_CAMBISTA ?? "",
                    TOTAL: valorAposta,
                    QTD_PULE: 1,
                    QTD_APOSTA: aposta.QTD_APOSTA ?? 1
                });
            } else {
                // Se já existir, incrementar o total
                cambista.TOTAL += valorAposta;
                cambista.QTD_PULE += 1;
                cambista.QTD_APOSTA = cambista.QTD_APOSTA + (aposta.QTD_APOSTA ?? 1)
            }
            });
        
            // Retornar um array com o total geral e os totais agrupados por cambista
            return [
                ...totalPorCambista.map(cambista => ({
                    CD_CAMBISTA: cambista.CD_CAMBISTA,
                    NOME_CAMBISTA: cambista.NOME_CAMBISTA,
                    TOTAL: cambista.TOTAL,
                    QTD_PULE: cambista.QTD_PULE,
                    QTD_APOSTA: cambista.QTD_APOSTA
                }))
            ];
    }, [ListaTodosJogoConfirmado]);

    const TotaisTodosJogoPendente = useMemo(() => {
        const totalPorCambista: { CD_CAMBISTA: number | null, NOME_CAMBISTA: string | null, TOTAL: number, QTD_PULE: number, QTD_APOSTA: number }[] = [];
    
        // Agrupar apostas por CD_CAMBISTA
        ListaTodosJogoPendente.forEach(aposta => {
            const valorAposta = parseFloat(String(aposta.VLR_APOSTA ?? "0"));
        
            // Encontrar o cambista atual
            let cambista = totalPorCambista.find(c => c.CD_CAMBISTA === aposta.CD_CAMBISTA);
        
            // Se não encontrar, adicionar um novo cambista
            if (!cambista) {
                totalPorCambista.push({
                    CD_CAMBISTA: aposta.CD_CAMBISTA!,
                    NOME_CAMBISTA: aposta.NOME_CAMBISTA ?? "-",
                    TOTAL: valorAposta,
                    QTD_PULE: 1,
                    QTD_APOSTA: aposta.QTD_APOSTA ?? 1
                });
            } else {
                // Se já existir, incrementar o total
                cambista.TOTAL += valorAposta;
                cambista.QTD_PULE += 1;
                cambista.QTD_APOSTA = cambista.QTD_APOSTA + (aposta.QTD_APOSTA ?? 1)
            }
            });
        
            // Retornar um array com o total geral e os totais agrupados por cambista
            return [
                ...totalPorCambista.map(cambista => ({
                    CD_CAMBISTA: cambista.CD_CAMBISTA,
                    NOME_CAMBISTA: cambista.NOME_CAMBISTA,
                    TOTAL: cambista.TOTAL,
                    QTD_PULE: cambista.QTD_PULE,
                    QTD_APOSTA: cambista.QTD_APOSTA
                }))
            ];
    }, [ListaTodosJogoPendente]);

    const TotalJogoConfirmado = useMemo(() => {
        // Usando reduce para calcular a quantidade total de apostas e o valor total arrecadado
        return ListaTodosJogoConfirmado.reduce(
            (acc, aposta) => {
                const qtdAposta = aposta.QTD_APOSTA || 0;
                const vlrAposta = parseFloat(String(aposta.VLR_APOSTA)) || 0;

                acc.QTD_APOSTA += qtdAposta;
                acc.VLR_APOSTA += vlrAposta;
                
                return acc;
            },
            { QTD_APOSTA: 0, VLR_APOSTA: 0 } // Valor inicial do acumulador
        );
    }, [ListaTodosJogoConfirmado]);

    const TotalJogoPendente = useMemo(() => {
        // Usando reduce para calcular a quantidade total de apostas e o valor total arrecadado
        return ListaTodosJogoPendente.reduce(
            (acc, aposta) => {
                const qtdAposta = aposta.QTD_APOSTA || 0;
                const vlrAposta = parseFloat(String(aposta.VLR_APOSTA)) || 0;

                acc.QTD_APOSTA += qtdAposta;
                acc.VLR_APOSTA += vlrAposta;
                
                return acc;
            },
            { QTD_APOSTA: 0, VLR_APOSTA: 0 } // Valor inicial do acumulador
        );
    }, [ListaTodosJogoPendente]);

    const Item = useMemo<IPule>(() => {
        if (Controller.Buscar.isSuccess && Controller.Buscar.data) {
            return Controller.Buscar.data;
        } else {
            return {} as IPule;
        };
    }, [Controller.Buscar.isSuccess, Controller.Buscar.data]);

    return {
        ListaJogo,
        ListaUsuario,
        ListaUsuarioJogo,
        ListaCambistaJogo,
        Item,
        ListaTodosJogoPendente,
        ListaTodosJogoConfirmado,
        TotaisTodosJogoConfirmado,
        TotaisTodosJogoPendente,
        TotalJogoConfirmado,
        TotalJogoPendente
    }
}

export interface IUseApostaDatasource {
    ListaJogo               : IAposta[];
    ListaUsuario            : IPule[];
    ListaUsuarioJogo        : IPule[];
    ListaCambistaJogo       : IPule[];
    Item                    : IPule;
    ListaTodosJogoPendente  : IPule[];
    ListaTodosJogoConfirmado: IPule[];
    TotaisTodosJogoConfirmado: any[];
    TotaisTodosJogoPendente : any[];
    TotalJogoConfirmado     : any;
    TotalJogoPendente       : any;
}

export default useApostaDatasource;