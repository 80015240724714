import { Form, InputGroup } from "react-bootstrap";
import { IGenericInformation } from "../../../../Interfaces";

interface Props {
    vinculaApostador: IGenericInformation[];
    setVinculaApostador: React.Dispatch<React.SetStateAction<IGenericInformation[]>>;
    formatter: any;
}

const VinculaApostadorE2 = ({vinculaApostador, setVinculaApostador, formatter} : Props) => {
    const chaveCelular  = 'CELULAR';
    const chaveNome     = 'NOME';
    
    return (
        <div>
            <Form.Group>
                <Form.Label>Encontrar apostador pelo <strong>celular</strong>:</Form.Label>
                <InputGroup>
                    <InputGroup.Text id="cdPais">+55</InputGroup.Text>
                    <Form.Control 
                        autoComplete="off"
                        disabled={String(vinculaApostador.find(x => x.CHAVE === chaveNome)?.VALOR ?? "") !== ""}
                        name={chaveCelular}
                        type={'text'}
                        value={String(vinculaApostador.find(x => x.CHAVE === chaveCelular)?.VALOR)}
                        placeholder="Celular..."
                        onChange={ e => {
                            const newValue = formatter.setMaskPhone(e.target.value);

                            setVinculaApostador(curr => 
                                curr.map(item =>
                                    item.CHAVE === chaveCelular
                                        ? {...item, VALOR: String(newValue)}
                                        : item
                                )
                            ) 
                        }}
                        size="lg"
                    />
                </InputGroup>
            </Form.Group> 

            <div className="w-100 mt-3 d-flex justify-content-center"><Form.Text>OU</Form.Text></div>

            <Form.Group className="mt-3">
                <Form.Label>Pesquisar apostador pelo <strong>nome</strong>:</Form.Label>
                <Form.Control 
                    autoComplete="off"
                    disabled={String(vinculaApostador.find(x => x.CHAVE === chaveCelular)?.VALOR ?? "") !== ""}
                    name={chaveNome}
                    type={'text'}
                    value={String(vinculaApostador.find(x => x.CHAVE === chaveNome)?.VALOR)}
                    placeholder="Nome..."
                    onChange={ e => {
                        setVinculaApostador(curr => 
                            curr.map(item =>
                                item.CHAVE === chaveNome
                                    ? {...item, VALOR: String(e.target.value)}
                                    : item
                            )
                        ) 
                    }}
                    size="lg"
                />
            </Form.Group> 
        </div>
    )
}

export default VinculaApostadorE2;